.menu-block {
	display:flex;
	flex-direction:row;
}
.menu-block .categories {
	position:fixed;
	padding-top:30px;
	width:240px;
	border-right:1px solid #ccc;
}
.menu-block .categories h4 {
	margin-left:10px;
}
.menu-block .categories .categories-items {
	padding-top:20px;
	height:calc(100vh - 50px);
	overflow-y:auto;
}
.menu-block .categories ul {
	margin:0 10px 0 0;
}
.menu-block .categories ul li.empty {
	margin-left:10px;
	color:#999;
}
.menu-block .categories ul li a {
	display:block;
	padding:15px 10px;
	cursor:pointer;
	text-decoration:none;
	color:#333;
	font-size:110%;
}
.menu-block .categories ul li.active a {
	border-radius:12px;
	background:#ddd;
}
.menu-block .categories ul li div {
	font-size:80%;
	color:#999;
}
.menu-block .catalog-items {
	padding:0 20px;
	width:100%;
	height:auto;
	margin-left:240px;
}
.menu-block .catalog-items .empty {
	margin:30px 0 0 10px;
}
.menu-block .catalog-items .empty h4 {
	margin-bottom:30px;
}
.menu-block .catalog-items .empty a {
	display:inline-flex;
	align-items:center;
	border:2px solid #000;
	font-size:15px;
	font-weight:bold;
	color:#000;
	text-decoration:none;
	padding:2px 12px 2px 6px;
	border-radius:4px;
}
.menu-block .catalog-items .empty a:hover {
	border-color:#999;
	background:rgba(255,255,255,.8);
	color:#00000080;
}
.menu-block .catalog-items .empty a:before {
	content:'';
	display:block;
	width:24px;
	height:24px;
	margin-right:4px;
	background:url(../../Assets/Images/add.svg) 0 0 no-repeat;
}
.menu-block .catalog-items .empty a:hover:before {
	opacity:.5;
}
.overlay {
	position:fixed;
	top:0;
	right:0;
	bottom:0;
	left:0;
	background:rgba(0,0,0,.5);
}
.right-side-panel {
	position:absolute;
	top:0;
	bottom:0;
	right:0;
	width:810px;
	background:#f4f3ef;
	box-shadow: 0 8px 10px -5px rgba(0,0,0,.2), 0 16px 24px 2px rgba(0,0,0,.14), 0 6px 30px 5px rgba(0,0,0,.12);
	padding:20px;
	overflow:auto;
}
.modal-menu-close {
	position: absolute;
	top:15px;
	right:25px;
	cursor:pointer;
}
.modal-menu-close img {
	width: 30px;
}