.no-back-title {
	margin-top:20px;
}
.subtitle-control {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
}
.form-container {
	max-width:860px;
	margin-top:20px;
	margin-bottom:10px;
	padding:20px;
	border-radius:4px;
	box-shadow:0 2px 2px rgba(204,197,185,.5);
	background:#fff;
	position:relative;
	z-index:1;
	zoom:1;
	overflow:hidden;
}
.form-container form {
	max-width:900px;
}
.form-container h4 {
	margin-bottom:20px;
}
.form-container .cell {
	margin:0 20px 20px 0;
}
.form-container .cell p {
	margin:5px 0;
}
.form-container .cell label span {
	display:block;
	margin:0 0 5px 0;
	color:#777;
}
.form-container .cell-online {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
}
.form-container .cell-online-start {
	justify-content:flex-start;
}
.form-container .cell-online-start .cell {
	width:250px;
}
.form-container .partner-photos {
	display:flex;
	flex-direction:row;
}
.form-container .partner-photos .partner-header-photo {
	width:600px;
	height:200px;
	border-radius:10px;
	border:4px solid #000;
	margin:0 10px 10px 0;
	overflow:hidden;
}
.form-container .partner-photos .partner-header-photo img {
	width:100%;
	height:100%;
	object-fit:cover;
}
.form-container .partner-photos .partner-logo-photo {
	width:100px;
	height:100px;
	border-radius:10px;
	border:4px solid #000;
	margin:0 10px 10px 0;
	overflow:hidden;
}
.form-container .partner-photos .partner-logo-photo img {
	width:100%;
	height:100%;
	object-fit:cover;
}


@media (max-width: 768px) {
	.form-container .cell {
		margin: 0 0 20px;
	}

	.form-container .cell-oneline .cell:nth-child(2){
		margin-left: 4%;
	}
}